import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';
import AuthenticatedNavbar from '../../components/AuthenticatedNavbar';

// List of classes
const classes = [
    { id: 1, class_name: '7A Set 1' },
    { id: 2, class_name: '7A Set 2' },
    { id: 3, class_name: '7A Set 3' },
    { id: 4, class_name: '8A Set 1' },
    { id: 5, class_name: '8A Set 2' },
    { id: 6, class_name: '8A Set 3' },
];

export default function AssignWorksheet() {
    const [searchQuery, setSearchQuery] = useState('');
    const [checkboxStates, setCheckboxStates] = useState(
        classes.reduce((acc, currentClass) => {
            acc[currentClass.id] = false; // Initialize all checkboxes as unchecked
            return acc;
        }, {})
    );
    const [dueDate, setDueDate] = useState('');
    const { isAuthenticated, user, logout, fetchUser } = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            await fetchUser();

            if (!isAuthenticated) {
                navigate('/');
            } else if (user && user.teacher === false) {
                navigate('/dashboard');
            }
        };

        checkAuth();
    }, [fetchUser, isAuthenticated, user, navigate]);

    // Display loading message or spinner while fetching user data
    if (!isAuthenticated || !user) {
        return <div>Loading...</div>;
    }

    // Filter classes based on search query
    const filteredClasses = classes.filter((currentClass) =>
        currentClass.class_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleCheckboxChange = (id) => {
        setCheckboxStates((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // Toggle the checkbox state
        }));
    };

    const formValidation = () => {
        let valid = true;

        if (Object.values(checkboxStates).every((isChecked) => !isChecked)) {
            alert('Please pick at least one class to assign questions to!');
            valid = false;
        }
        if (dueDate === '') {
            alert('Please pick a due date!');
            valid = false;
        }

        const curr = new Date(dueDate);
        const today = new Date();
        if (curr < today) {
            alert('Please select a date later than today!');
            valid = false;
        }

        if (valid) {
            console.log('This is a valid form');
        }
    };

    const submitForm = () => {
        console.log('Submitting form to backend...');
    };

    return (
        <div>
            <AuthenticatedNavbar logout={logout} user={user} />
            <div className='mt-[20px]'>
                <h1 className='text-center text-3xl'><b>Assign Worksheet</b></h1>
                <p className='text-center mt-[10px]'>This page you can assign worksheets to classes.</p>
                
                <p className='text-center'>
                    Currently already assigned for:
                    <br/>
                    ...
                </p>

                {/* Search Input */}
                <div className='text-center mt-[20px]'>
                    <input
                        type='text'
                        className='border p-[5px] mb-[20px] rounded'
                        placeholder='Search classes...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                {/* Filtered Classes List */}
                <div>
                    {filteredClasses.map((currentClass) => (
                        <div key={currentClass.id} className='text-center'>
                            <span>
                                {currentClass.class_name}
                                <input
                                    type='checkbox'
                                    className='ml-[10px]'
                                    checked={checkboxStates[currentClass.id]} // Controlled state
                                    onChange={() => handleCheckboxChange(currentClass.id)}
                                />
                            </span>
                        </div>
                    ))}
                </div>

                <div className='text-center mt-[10px]'>
                    <p>Due Date:</p>
                    <input type='date' className='' onChange={(e) => setDueDate(e.target.value)} />
                </div>

                <button onClick={formValidation} className='btn btn-primary mt-[20px] rounded-[5px] flex ml-auto mr-auto'>
                    Save and Assign
                </button>
            </div>
        </div>
    );
}
