import React, { useEffect } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import CreateWorksheet from './authenticated/CreateWorksheet';
//import { useAuthStore } from '../store/authStore';

import CookiesBanner from '../components/CookiesBanner';

export default function GCSE() {

    //const { isAuthenticated, user, logout, fetchUser } = useAuthStore();

    useEffect(()=> {
        document.title = 'GCSE | Maths Den';
    }, []);

    return(
        <div className="flex flex-col min-h-screen">
            <Navbar />

            <CookiesBanner />

            <div className='mt-[20px] flex-grow'>
                <h1 className='text-3xl text-center ml-[10px] mr-[10px]'><b>GCSE Worksheet Generator</b></h1>
                <p className='text-center mb-[10px]'>Create a GCSE Maths Worksheet</p>
                <div>
                    <CreateWorksheet />
                </div>
            </div>
            <Footer />
        </div>
    );
}