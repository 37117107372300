import React, { useState, useEffect } from "react";

const CookiesBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(()=> {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    useEffect(()=> {
        if (isVisible) {
            document.getElementById('cookies_modal').showModal();
        }
    }, [isVisible]);

    // Handle consent
    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "true");
        document.getElementById('cookies_modal').close();
        setIsVisible(false);
    }

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "false");
        document.getElementById('cookies_modal').close();
        setIsVisible(false);
    }

    return (
        <div>
            <dialog id="cookies_modal" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Cookies Policy</h3>
                    <p className="py-4">We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.</p>
                    <button onClick={handleAccept} className='btn btn-success text-white rounded-[5px]'>Accept</button>
                    <button onClick={handleDecline} className='btn btn-error text-white ml-[5px] rounded-[5px]'>Decline</button>

                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn">Close</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default CookiesBanner;