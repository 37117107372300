import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import newtinn from '../assets/images/newtinn.jpeg';

import CookiesBanner from "../components/CookiesBanner";

export const About = () => {
    useEffect(() => {
        document.title = 'About | Maths Den';
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />

            <CookiesBanner />

            <div className='mt-[20px] flex-grow'>
                <h1 className='text-3xl text-center'><b>About</b></h1>
                <div className='max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto mt-5 px-4 text-center'>
                    <p>
                        Welcome to Maths Den! At Maths Den we provide mathematics resources to make it easier to learn and remove the tedious task of finding resources.
                        <br /><br/>
                        We currently provide a Worksheet Generator for GCSE students that automatically creates worksheets to enable teachers and students to focus on the actual maths rather than finding questions to use.
                        Alongside our GCSE Worksheet Generator, we are working on a paid version of our product that includes GCSE Mathematics Higher Topics and more.
                    </p>

                    <h2 className='font-bold text-l mt-[50px]'>Meet the Team</h2>
                    <div className='flex flex-col md:flex-row mt-[10px] max-w-[550px] ml-auto mr-auto'>
                        <img alt='Matt, creator of Maths Den' src={newtinn} className='h-[200px] mx-auto md:mx-0 mt-[5px]' />
                        <p className='md:ml-[20px] mt-4 md:mt-0 text-center md:text-left'>
                            <b>Matt</b><br/>
                            Hi! I'm Matt and I created Maths Den. 
                            <br/>
                            I developed everything on this website from the Worksheet Generators to this page.
                            <br/>
                            I was a Maths Tutor for 5 years and decided to create Maths Den due to being bored of using the same questions over and over again.
                            <br/>
                            <br/>
                            In my free time I enjoy watching sports and playing guitar.
                            I don't have a picture of myself at the moment so here is a picture of a dog from Pinterest!
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}