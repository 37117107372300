import React, { useEffect } from "react";
import AuthenticatedNavbar from '../../components/AuthenticatedNavbar';

import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';

const worksheets = [
    { 'worksheet_name': 'Solving Equations', 'date_created': '01/09/2024' },
    { 'worksheet_name': 'Factorising Quadratics', 'date_created': '01/09/2024' },
]

export default function UserWorksheets() {
    const { isAuthenticated, user, logout, fetchUser } = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
        void fetchUser().then(()=>{
            if (!isAuthenticated) {
                navigate('/');
            }
            if (user.teacher === false) {
                navigate('/dashboard');
            }
        });
    }, [fetchUser]);

    // Only render when user and isAuthenticated are valid
    if (!isAuthenticated || !user) {
        return null;
    }

    return(
        <div>
            <AuthenticatedNavbar logout={logout} user={user} />
            <div className='mt-[20px]'>
                <h1 className='text-center text-3xl'><b>Your Worksheets</b></h1>
                
                {/* Add container with responsive width */}
                <div className="max-w-full sm:w-[500px] mt-[20px] ml-auto mr-auto">

                    {/* Table Header */}
                    <div className="flex flex-col sm:flex-row border-b border-gray-300 font-bold">
                        <div className="flex-1 border border-gray-300 px-2 py-1">Worksheet Name</div>
                        <div className="flex-1 border border-gray-300 px-2 py-1">Date Created</div>
                        { user.teacher === true && <div className="flex-1 border border-gray-300 px-2 py-1"></div>}
                    </div>

                    {
                        worksheets.map((worksheet, row)=>
                            <div className="flex flex-col mt-[10px] sm:mt-[0px] sm:flex-row border-b border-gray-300" key={row}>
                                <div className="flex-1 border border-gray-300 px-2 py-1">{worksheet.worksheet_name}</div>
                                <div className="flex-1 border border-gray-300 px-2 py-1">{worksheet.date_created}</div>
                                { user.teacher === true &&
                                <div className="flex-1 border border-gray-300 px-2 py-1">
                                    <a href='/assign_worksheet' className='btn btn-secondary rounded-[5px] w-[100px]'>
                                        Assign
                                    </a>
                                </div>
                                }
                            </div>
                        )
                    }
    
                </div>
            </div>
        </div>
    );
}
