import React, { useState, useEffect, useRef } from 'react';

import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

import AuthenticatedNavbar from '../../components/AuthenticatedNavbar';

import { topicDecision } from '../../utils/functions/GCSEQuestionGenerator';

import WorksheetQuestions from '../../components/Worksheet Generation/WorksheetQuestions';
import WorksheetAnswers from '../../components/Worksheet Generation/WorksheetAnswers';

import SaveWorksheet from './SaveWorksheet';

//import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas-pro';
import { useReactToPrint } from 'react-to-print';

import { useNavigate, useLocation } from 'react-router-dom';
//import { useAuthStore } from '../../store/authStore';
import WorksheetPreview from '../../components/Worksheet Generation/Online/WorksheetPreview';
import WorksheetQuestionsButton from '../../components/PDF Creation/WorksheetQuestionsButton';
import WorksheetAnswersButton from '../../components/PDF Creation/WorksheetAnswersButton';

const LatexOptions = {
    delimiters: [
        { left: '~~', right: '~~', display: true },  // Block LaTeX
        { left: '~', right: '~', display: false }, // Inline LaTeX
    ],
  };

const topicData = [
    {
        'topic': 'number', 'colour': '#007FFF', 'subtopic_colour': '#0277ED', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Addition', 'value': 'addition', 'selected': false},
        {'label': 'Subtraction', 'value': 'subtraction', 'selected': false},
        {'label': 'Time', 'value': 'time', 'selected': false},
        {'label': 'Simplifying Fractions', 'value': 'simplify_fractions', 'selected': false},
        {'label': 'Ordering Fractions', 'value': 'ordering_fractions', 'selected': false},
        {'label': 'Place Value', 'value': 'place_value', 'selected': false},
        {'label': 'Rounding', 'value': 'rounding', 'selected': false},
        {'label': 'Negative numbers', 'value': 'negative_numbers', 'selected': false},
        {'label': 'Powers and roots', 'value': 'powers_and_roots', 'selected': false},
        {'label': 'BIDMAS', 'value': 'bidmas', 'selected': false},
        {'label': 'Factors and Multiples', 'value': 'factors_and_multiples', 'selected': false},
        {'label': 'Fractions of an amount', 'value': 'fraction_of_amount', 'selected': false},
        {'label': 'Fractions, Decimals and Percentages', 'value': 'fdp', 'selected': false},
        {'label': 'Averages', 'value': 'averages', 'selected': false},
        {'label': 'Error Intervals', 'value': 'error_intervals', 'selected': false},
        {'label': 'Percentages', 'value': 'percentages', 'selected': false},
        {'label': 'Exchange Rates', 'value': 'exchange_rates', 'selected': false},
        {'label': 'Conversions and Units', 'value': 'conversions_units', 'selected': false},
        {'label': 'Best Buy', 'value': 'best_buy', 'selected': false},
        {'label': 'Compound Interest and Depreciation', 'value': 'compound_interest', 'selected': false},
        {'label': 'Reverse Percentages', 'value': 'reverse_percentages', 'selected': false},
        {'label': 'Standard Form', 'value': 'standard_form', 'selected': false},
        {'label': 'HCF and LCM', 'value': 'hcf_and_lcm', 'selected': false },
        {'label': 'Indices Laws', 'value': 'indices_laws', 'selected': false },
        {'label': 'Estimation', 'value': 'estimation', 'selected': false },
        {'label': 'Fractions', 'value': 'fractions', 'selected': false },
        /*{'label': 'Surds', 'value': 'surds', 'selected': false },*/
        {'label': 'Percentage Increase', 'value': 'percentage_increase', 'selected': false },
        {'label': 'Percentage Decrease', 'value': 'percentage_decrease', 'selected': false },
        ]
    },
    { 
        'topic': 'algebra', 'colour': '#72D742', 'subtopic_colour': '#69C53D', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Simplifying Algebra', 'value': 'simplify_algebra', 'selected': false},
        {'label': 'Expanding Expressions', 'value': 'expanding_expressions', 'selected': false },
        {'label': 'Factorising Expressions', 'value': 'factorising_expressions', 'selected': false },
        {'label': 'Solving Equations - One Step', 'value': 'solving_equations_one_step', 'selected': false },
        {'label': 'Solving Equations - Two Step', 'value': 'solving_equations_two_step', 'selected': false },
        {'label': 'Solving Equations - Unknown on both sides', 'value': 'solving_equations_both_sides', 'selected': false },
        {'label': 'Solving Equations - Brackets one side', 'value': 'solving_equations_brackets_one_side', 'selected': false },
        {'label': 'Substitution', 'value': 'substitution', 'selected': false },
        {'label': 'nth term', 'value': 'nth_term', 'selected': false },
        {'label': 'Expanding Quadratic expressions', 'value': 'expanding_quadratic_expressions', 'selected': false },
        {'label': 'Factorising Quadratic expressions', 'value': 'factorising_quadratic_expressions', 'selected': false },
        {'label': 'Solving Quadratics', 'value': 'solving_quadratics', 'selected': false },
        {'label': 'Solving Simultaneous Equations', 'value': 'solving_simultaneous_equations', 'selected': false },
        {'label': 'Inequalities', 'value': 'inequalities', 'selected': false},
        ]
    },
    { 
        'topic': 'ratio, proportion & rates of change', 'colour': '#FF4D4D', 'subtopic_colour': '#E33939', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
            {'label': 'Simplifying Ratios', 'value': 'simplifying_ratios', 'selected': false },
            {'label': 'Finding Quantities using ratios', 'value': 'finding_quantities_using_ratios', 'selected': false },
            {'label': 'Ratio', 'value': 'ratio', 'selected': false},
            {'label': 'Proportion', 'value': 'proportion', 'selected': false},
            {'label': 'Speed', 'value': 'speed', 'selected': false},
        ]
    },
    { 
        'topic': 'probability', 'colour': '#A51CFA', 'subtopic_colour': '#991AE8', 'subtopic_colour_text': 'white', 'extended': false, 'subtopics': 
        [
        {'label': 'Probability', 'value': 'probability', 'selected': false },
        ]
    },
];

const settings = {
    'question_number': ['Question Number', 5],
    'title': 'Worksheet',
    'is_online': false,
    'extended': false
};

const questionComplexity = 'basic';
const user = false;

export default function CreateWorksheet() {

    const [topics, setTopics] = useState(topicData);
    const [filteredTopics, setFilteredTopics] = useState("");
    const [viewWorksheet, setViewWorksheet] = useState(false);
    const [worksheetQuestions, setWorksheetQuestions] = useState([]);
    const [worksheetSettings, setWorksheetSettings] = useState(settings);
    const [questionNumber, setQuestionNumber] = useState(5);
    const [worksheetOnline, setWorksheetOnline] = useState(false);
    const [showWorksheet, setShowWorksheet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userSelectedTopics, setUserSelectedTopics] = useState([]);

    const [questionEdited, setQuestionEdited] = useState(-1);
    const [answerEdited, setAnswerEdited] = useState(-1);
    const [stepEdited, setStepEdited] = useState(-1);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [editing, setEditing] = useState(false);
    const [printing, setPrinting] = useState(false);

    const [printMode, setPrintMode] = useState('Questions');

    const [saveWorksheetScreen, setSaveWorksheetScreen] = useState(false);

    //const { isAuthenticated, user, logout, fetchUser } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();

    /*useEffect(() => {
        const checkAuth = async () => {
            await fetchUser();
        };

        checkAuth();
    }, [fetchUser]);*/

    useEffect(()=> {
        if (userSelectedTopics.includes('ordering_fractions') || userSelectedTopics.includes('error_intervals') || userSelectedTopics.includes('solving_quadratics')) {
            let currentWorksheetSettings = worksheetSettings;
            currentWorksheetSettings.is_online = false;
            setWorksheetOnlineSettings(false);
        }
    }, [userSelectedTopics]);

    const displayedTopics = topics.map((topic) => {
        const filteredSubtopics = filteredTopics
            ? topic.subtopics.filter((subtopic) =>
                subtopic.label.toLowerCase().includes(filteredTopics.toLowerCase())
            )
            : topic.subtopics; // Show all subtopics if no search query

        return {
            ...topic,
            filteredSubtopics: filteredSubtopics, // All subtopics if no search or filtered subtopics
            extended: filteredTopics && filteredSubtopics.length > 0 ? true : topic.extended // Set extended to true if there's a search query
        };
    });

    const toggleTopic = (topicName) => {
        const updatedTopics = topics.map((topic) => {
            if (topic.topic === topicName) {
                return { ...topic, extended: !topic.extended };
            }
            return topic;
        });
        setTopics(updatedTopics);
    }

    const toggleSubtopic = (subtopicId) => {
        const updatedTopics = topics.map((topic) => {
            const updatedSubtopics = topic.subtopics.map((subtopic) => {
            if (subtopic.value === subtopicId) {
                return { ...subtopic, selected: !subtopic.selected }; // Toggle selected state
            }
            return subtopic; // Return the subtopic as is if it does not match
            });
            return { ...topic, subtopics: updatedSubtopics }; // Update topic with modified subtopics
        });
        setTopics(updatedTopics); // Update the state with the modified topics

        // Update selected values for userSelectedTopics
        const selectedValues = updatedTopics.flatMap(topic =>
            topic.subtopics.filter(subtopic => subtopic.selected).map(subtopic => subtopic.value)
        );
        setUserSelectedTopics(selectedValues); // Update state for selected subtopics
    };

    const toggleWorksheetSettings = () => {
        
        if (worksheetSettings.extended === false) {
            setWorksheetSettings({
                ...worksheetSettings, extended: true
            });
            return;
        }
        if (worksheetSettings.extended === true) {
            setWorksheetSettings({
                ...worksheetSettings, extended: false
            });
            return;
        }
    }

    const setNumberQuestions = (number) => {
        setQuestionNumber(number);
        let currentWorksheetSettings = worksheetSettings;
        currentWorksheetSettings.question_number[1] = number;

        setWorksheetSettings(currentWorksheetSettings);
    }

    const setWorksheetOnlineSettings = (online) => {
        // Create a copy of the current worksheet settings
        const updatedWorksheetSettings = { ...worksheetSettings };

        // Update the `is_online` property with the new checkbox value
        updatedWorksheetSettings.is_online = online;

        // Update the state with the modified settings object
        setWorksheetSettings(updatedWorksheetSettings);
    }

    const setWorksheetTitle = (title) => {
        setWorksheetSettings((prevSettings) => ({
            ...prevSettings,
            title: title,
          }));
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    const generateWorksheet = async() => {
        // clearing the current worksheet
        setWorksheetQuestions([]);

        // set all editing to false
        setQuestionEdited(-1);
        setAnswerEdited(-1);
        setStepEdited(-1);
        setCurrentQuestion('');
        setEditing(false);

        // getting the selected subtopics
        var selectedTopics = [];

        for (let i=0; i < topics.length; i++) {

            for (let x=0; x < topics[i].subtopics.length; x++) {
                if (topics[i].subtopics[x].selected === true) {
                    selectedTopics.push(topics[i].subtopics[x].value);
                }
            }
        }

        if (selectedTopics.length === 0) {
            //alert('Error - no topics selected!');
            setErrorMessage('No topics have been selected!');
            document.getElementById('my_modal_1').showModal();
            return;
        }
        if (worksheetSettings.title === '') {
            setErrorMessage('Please set a worksheet title!');
            document.getElementById('my_modal_1').showModal();
            return;
        }
        if (selectedTopics.length !== 0 && worksheetSettings.title !== '') {
            setLoading(true);
        }

        let questions = [];

        if (selectedTopics.length > 0 && worksheetSettings.title !== '') {
            const promises = [];

            let topicNumber = 0;
            let numberOfQuestions = worksheetSettings.question_number[1];
            console.log(worksheetSettings);
            while (numberOfQuestions > 0) {
                questions.push(topicDecision(selectedTopics[topicNumber], questionComplexity));
                topicNumber += 1;
                if (topicNumber > selectedTopics.length-1) {
                    topicNumber = 0;
                }
                if (numberOfQuestions === 1) {
                    await delay(500);
                }
                numberOfQuestions -= 1;
            }

            setWorksheetQuestions(questions);
            setViewWorksheet(true);
            setLoading(false);
            window.scrollTo(0, 0);
        } else {
            if (selectedTopics.length === 0) {
                //alert('Error - no topics selected!');
                document.getElementById('my_modal_1').showModal();
            }
            if (worksheetSettings.title === '') {
                alert('Error - please set a worksheet title!');
            }
        }
    }

    const clearTopics = () => {
        const resetTopics = topics.map(topic => {
            const resetSubtopics = topic.subtopics.map(subtopic => ({
                ...subtopic,
                selected: false // Set selected to false
            }));
            return { ...topic, subtopics: resetSubtopics }; // Update topic with reset subtopics
        });
    
        setTopics(resetTopics); // Update state with reset topics
        setUserSelectedTopics([]); // Clear userSelectedTopics
    }

    const saveEditedQuestion = (number) => {
        let questions = worksheetQuestions;
        worksheetQuestions[number][0] = currentQuestion;
        setWorksheetQuestions(worksheetQuestions);
        setCurrentQuestion('');
        setQuestionEdited(-1);
        setEditing(false);
    }

    const saveEditedAnswer = (number) => {
        let questions = worksheetQuestions;
        worksheetQuestions[number][1][0] = currentQuestion;
        worksheetQuestions[number][1][1] = `~${currentQuestion}~`;
        setWorksheetQuestions(worksheetQuestions);
        setCurrentQuestion('');
        setAnswerEdited(-1);
        setEditing(false);
    }

    const saveEditedSteps = (number) => {
        let questions = worksheetQuestions;
        worksheetQuestions[number][2] = currentQuestion;
        setWorksheetQuestions(worksheetQuestions);
        setCurrentQuestion('');
        setStepEdited(-1);
        setEditing(false);
    }

    const printWorksheet = async (worksheet, worksheetType) => {
        const input = document.getElementById(worksheet); // Get the element by ID
        const sections = input.getElementsByClassName('page'); // Get all elements with the "page" class

        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = 210; // Width of A4 in mm
        const pdfHeight = 297; // Height of A4 in mm

        const margin = { top: 10, bottom: 10, left: 15, right: 15 };
        const contentWidth = pdfWidth - margin.left - margin.right;
        const contentHeight = pdfHeight - margin.top - margin.bottom;

        setPrinting(true);

        // Temporarily show hidden elements
        input.classList.remove('hidden-to-print');

        for (let i = 0; i < sections.length; i++) {
            const section = sections[i];

            const canvas = await html2canvas(section, {
                scale: 3,
                useCORS: true,
            });

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = contentWidth;
            const imgHeight = (canvas.height * contentWidth) / canvas.width;

            const xOffset = margin.left;
            const yOffset = margin.top;

            pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);

            if (i < sections.length - 1) {
                pdf.addPage();
            }
        }

        input.classList.add('hidden-to-print'); // Re-hide elements after generating the PDF

        let worksheetFilename = worksheetSettings.title.replaceAll(' ','_');
        if (worksheetType === 'Answers') {
            worksheetFilename += '_answers';
        }

        pdf.save(worksheetFilename+'.pdf');
        setPrinting(false);
    }

    // Only render when user and isAuthenticated are valid
    /*if ((!isAuthenticated || !user) && (location.pathname === '/create_worksheet')) {
        navigate('/');
    }*/

    return (
        <div>
            {location.pathname === '/create_worksheet' && user && <AuthenticatedNavbar user={user} />}

            <dialog id="my_modal_1" className="modal">
                <div className="modal-box bg-error text-white">
                    <h3 className="font-bold text-lg">Error</h3>
                    <p className="py-4">{errorMessage}</p>
                    <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn btn-ghost rounded-[10px] text-white">Close</button>
                    </form>
                    </div>
                </div>
            </dialog>

            { showWorksheet === false &&
            <div className='mt-[20px] pb-[100px]'>
                {location.pathname === '/create_worksheet' &&
                <div>
                    <h1 className='text-3xl text-center'><b>GCSE Worksheet</b></h1>
                    <p className='text-center mb-[10px]'>Create a GCSE Maths Worksheet</p>
                </div> }
                {
                    loading === true &&
                    <div className='flex flex-col items-center ml-auto mr-auto'>
                        <span className="loading loading-spinner loading-lg mt-[10px] mb-[10px]"></span>
                        <p className='text-center'>Loading...</p>
                    </div>
                }
                <div className='mt-[20px]'>
                    {
                        viewWorksheet ?
                            <div>
                                { loading === false &&
                                <div>
                                    { worksheetOnline &&
                                        <div>
                                            <input onClick={()=>setWorksheetOnline(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '125px'}} value='Back to edit' /> 
                                            <WorksheetPreview worksheet={worksheetQuestions} />
                                        </div>
                                    }
                                    { worksheetSettings.is_online === false ?
                                        <div className='w-[300px] sm:w-[350px] md:w-[500px] lg:w-[700px] ml-auto mr-auto'>
                                            { saveWorksheetScreen ?
                                                <div>
                                                    { !worksheetOnline &&
                                                    <div>
                                                        <h2 className='text-center text-xl mb-[10px]'><b>Save Worksheet</b></h2>
                                                        {/*<p className='text-center text-[red]'><b>If you have edited a question, please remember to edit the answer and steps as well!</b></p>
                                                        <p className='text-center'>Choose an option below to decide what to do with your newly generated worksheet.</p>*/}
                                                        
                                                        { 
                                                            (userSelectedTopics.indexOf('ordering_fractions') !== -1) || (userSelectedTopics.indexOf('error_intervals') !== -1) || (userSelectedTopics.indexOf('solving_quadratics') !== -1) ?
                                                            <div className="tooltip tooltip-error ml-auto mr-auto flex items-center" data-tip="Online Worksheet completion is disabled due to topics selected.">
                                                                <input type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mt-[10px] disabled:text-[white] disabled:cursor-not-allowed' style={{backgroundColor: '#6e6d6d', width: '225px'}} value='Complete worksheet online' /> 
                                                            </div>
                                                            :
                                                            <input onClick={()=>setWorksheetOnline(true)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mt-[10px] disabled:text-[white] disabled:cursor-not-allowed' style={{backgroundColor: '#624CAB', width: '225px'}} value='Complete worksheet online' /> 
                                                        }
                                                        {/*<input onClick={()=>printWorksheet('questions', 'Questions')} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mt-[10px]' style={{backgroundColor: '#DD7373', width: '225px'}} value='Save to PDF' /> */}
                                                        <button className='btn btn-neutral ml-auto mr-auto flex justify-center mt-[10px] mb-[30px] rounded-[5px] w-[225px] text-white' onClick={()=>document.getElementById('my_modal_2').showModal()}>Download</button>
                                                        <dialog id="my_modal_2" className="modal">
                                                        <div className="modal-box">
                                                            <h3 className="text-center text-lg">Choose the document you would like to download</h3>
                                                            
                                                            <select value={printMode} onChange={(e)=>setPrintMode(e.target.value)} className="select select-bordered w-[200px] flex justify-center ml-auto mr-auto mt-[10px] rounded-[10px]">
                                                                <option selected disabled>Pick an option</option>
                                                                <option>Questions</option>
                                                                <option>Answers</option>
                                                            </select>

                                                            {
                                                                printMode === 'Questions' &&
                                                                <WorksheetQuestionsButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'questions'} />
                                                            }
                                                            {
                                                                printMode === 'Answers' &&
                                                                <WorksheetAnswersButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'answers'} />
                                                            }

                                                            <div className="modal-action">
                                                            <form method="dialog">
                                                                {/* if there is a button in form, it will close the modal */}
                                                                <button className="btn rounded-[10px]">Close</button>
                                                            </form>
                                                            </div>
                                                        </div>
                                                        </dialog>
                                                        
                                                        

                                                        {/*<WorksheetQuestionsButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'questions'} />
                                                        <WorksheetAnswersButton worksheet={worksheetQuestions} title={worksheetSettings.title} type={'answers'} />*/}
                                                        {/*<input onClick={()=>printWorksheet('answers', 'Answers')} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px] mt-[10px]' style={{backgroundColor: '#DD7373', width: '225px'}} value='Save Answers to PDF' /> */}
                                                        { user &&
                                                        <div>
                                                            {user.teacher === true && <input onClick={()=>alert('saving worksheet to profile')} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px] mt-[10px]' style={{backgroundColor: '#DD7373', width: '200px'}} value='Save Worksheet to Profile' />}
                                                        </div>
                                                        }
                                                        <input onClick={()=>setSaveWorksheetScreen(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '225px'}} value='Back to Worksheet' /> 

                                                        <div className={printing ? 'mt-[800px]' : ''}>
                                                            <div id='questions' className='hidden-to-print'>
                                                                <WorksheetQuestions worksheetQuestions={worksheetQuestions} worksheetTitle={worksheetSettings.title} />
                                                            </div>
                                                            <div id='answers' className='hidden-to-print'>
                                                                <WorksheetAnswers worksheetQuestions={worksheetQuestions} worksheetTitle={worksheetSettings.title} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    <div>
                                                        <input onClick={()=>setViewWorksheet(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '150px'}} value='Back to Menu' /> 
                                                        <input onClick={()=>setSaveWorksheetScreen(true)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: 'blue', width: '150px'}} value='Save worksheet' /> 
                                                    </div>

                                                    <div className='border-solid border-2 border-[black] pt-[10px] pb-[10px] pl-[25px] pr-[25px] rounded-[10px]'>
                                                        <h2><b><u>{worksheetSettings.title}</u></b></h2>
                                                        <div>
                                                            {
                                                                worksheetQuestions.map((question, questionNumber)=> (
                                                                    <div key={questionNumber*1000} className='mb-[20px] mt-[30px]'>
                                                                        <div>
                                                                            { editing && questionEdited === questionNumber ?
                                                                                <div>
                                                                                    Q{questionNumber+1}.<input type='text' onChange={(text)=>setCurrentQuestion(text.target.value)} placeholder="Type here" className="input input-bordered w-full max-w-xs mb-[10px] ml-[10px]" value={currentQuestion} maxLength={225} />
                                                                                    <br/>
                                                                                    <input onClick={() => saveEditedQuestion(questionNumber)} type='submit' className='btn topicButton hover:cursor-pointer mb-[10px]' style={{backgroundColor: 'red', width: '125px'}} value='Save Question' />  
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <p className='mb-[10px] break-words' data-testid='question_text'>Q{questionNumber+1}. <Latex delimiters={LatexOptions.delimiters}>{question[0]}</Latex></p>
                                                                                    {/*<input onClick={()=>editQuestion(questionNumber)} type='submit' className='btn topicButton hover:cursor-pointer mb-[10px]' style={{backgroundColor: '#4B543B', width: '125px'}} value='Edit Question' /> */}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className='h-[300px] border-solid border-2 border-[black] rounded-[5px]'>
                                                                            <div className='flex items-end justify-end mt-[260px] mr-[10px] break-words overflow-hidden'>
                                                                                <p>...............................................................</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="divider before:bg-[black] after:bg-[black]">
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='border-solid border-2 border-[black] pt-[10px] pb-[10px] pl-[25px] pr-[25px] mt-[20px] rounded-[10px]'>
                                                        <h2><b><u>{worksheetSettings.title} answers</u></b></h2>
                                                        {
                                                            worksheetQuestions.map((question, questionNumber)=> (
                                                                <div key={questionNumber*1000} className='mb-[10px]'>
                                                                    <div className='break-words'>
                                                                        Q{questionNumber+1}. <Latex delimiters={LatexOptions.delimiters}>{question[0]}</Latex>
                                                                        <br/>
                                                                        <div>
                                                                            { editing && answerEdited === questionNumber ?
                                                                                <div>
                                                                                    Answer: <input type='text' onChange={(text)=>setCurrentQuestion(text.target.value)} placeholder="Type here" className="input input-bordered w-full max-w-xs mb-[10px] ml-[10px]" value={currentQuestion} maxLength={225} />
                                                                                    <br />
                                                                                    <input onClick={()=>saveEditedAnswer(questionNumber)} type='submit' className='btn topicButton hover:cursor-pointer' style={{backgroundColor: 'red', width: '125px'}} value='Save Answer' /> 
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <div className='break-words'>
                                                                                        Answer: <Latex delimiters={LatexOptions.delimiters}>{question[1][1]}</Latex>
                                                                                    </div>
                                                                                    {/*
                                                                                    <div>
                                                                                        <br/>
                                                                                        <input onClick={()=>editAnswer(questionNumber)} type='submit' className='btn topicButton hover:cursor-pointer' style={{backgroundColor: 'blue', width: '125px'}} value={'Edit Q'+(questionNumber+1)+' Answer'} /> 
                                                                                    </div>*/}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <br/>
                                                                        <div>
                                                                            { editing && stepEdited === questionNumber ?
                                                                                <div className='break-words'>
                                                                                    Steps: 
                                                                                    <br/>
                                                                                    <textarea onChange={(text)=>setCurrentQuestion(text.target.value)} placeholder="Type here" className="textarea textarea-bordered w-full max-w-m h-[100px] mb-[10px] ml-[10px] rounded-[5px]" value={currentQuestion} maxLength={225} />
                                                                                    <br/>
                                                                                    <input onClick={()=>saveEditedSteps(questionNumber)} type='submit' className='btn topicButton hover:cursor-pointer' style={{backgroundColor: 'red', width: '125px'}} value={'Save Q'+(questionNumber+1)+' Steps'} /> 
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    Steps: {question[2]}
                                                                                    {/*
                                                                                    <br/>
                                                                                    <input onClick={()=>editSteps(questionNumber)} type='submit' className='btn topicButton hover:cursor-pointer mb-[10px] mt-[10px]' style={{backgroundColor: 'blue', width: '125px'}} value={'Edit Q'+(questionNumber+1)+' Steps'} /> 
                                                                                    */}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <input onClick={()=>setViewWorksheet(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#9C9C9C', width: '150px'}} value='Back to menu' /> 
                                            <input onClick={()=>setWorksheetOnlineSettings(false)} type='submit' className='btn topicButton hover:cursor-pointer flex justify-center mb-[10px]' style={{backgroundColor: '#624CAB', width: '150px'}} value='Edit Worksheet' /> 
                                            <WorksheetPreview worksheet={worksheetQuestions} />
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                        :
                        <div>
                            { loading === false &&
                            <div className='w-[300px] ml-auto mr-auto flex flex-col justify-center'>
                                {/* Search Bar with SVG Icon */}
                                <div className="relative mb-[10px]">
                                    <input
                                    type="text"
                                    placeholder="Search subtopics..."
                                    value={filteredTopics}
                                    onChange={(e) => setFilteredTopics(e.target.value)} // Update the search query state
                                    className="w-full p-2 border rounded pl-8 pr-8" // Set full width, padding for the icon
                                    style={{ height: '40px' }} // Fixed height for consistent sizing
                                    />
                                    <div className="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-search"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                    </svg>
                                    </div>
                                </div>
                                
                                {displayedTopics.map((topic, i) => (
                                    <div key={i} className="mb-[10px]">
                                    {/* Main Topic Button */}
                                    <input
                                        onClick={() => toggleTopic(topic.topic)} // Toggle topic on click
                                        type="submit"
                                        className="btn topicButton hover:cursor-pointer"
                                        style={{ backgroundColor: topic.colour }}
                                        value={topic.topic.charAt(0).toUpperCase() + topic.topic.slice(1)}
                                    />

                                    {/* Display Subtopics if Extended */}
                                    {(topic.extended || (filteredTopics && topic.filteredSubtopics.length > 0)) && (
                                        <div>
                                        {topic.filteredSubtopics.map((subtopic, x) => (
                                            <div key={x}>
                                            <div
                                                className="topicButton mb-[5px] mt-[5px]"
                                                style={{ backgroundColor: topic.subtopic_colour }}
                                            >
                                                <div className="flex flex-row items-center">
                                                <p>{subtopic.label}</p>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => toggleSubtopic(subtopic.value)} // Toggle subtopic on change using unique id
                                                    checked={subtopic.selected} // Checked state
                                                    className="topicSelectButton"
                                                    style={{ backgroundColor: topic.subtopic_colour }}
                                                />
                                                </div>
                                            </div>
                                            </div>
                                        ))}
                                        </div>
                                    )}
                                    </div>
                                ))}

                                <input type='submit' onClick={()=>toggleWorksheetSettings()} className='btn topicButton hover:cursor-pointer' style={{backgroundColor: '#E2B335'}} value='Worksheet Settings' />
                                <div>
                                    { worksheetSettings.extended ?
                                            <div>
                                                {
                                                    Object.entries(worksheetSettings).map(([setting, value], i)=>
                                                        <div key={i}>
                                                            { setting !== 'extended' &&
                                                                <div>
                                                                    <div className='topicButton mb-[5px] mt-[5px]' style={{backgroundColor: '#c99f2e'}}>
                                                                        <div className='flex flex-row items-center '>
                                                                            { setting === 'question_number' ? <p>{value[0]}</p> : null }
                                                                            { setting === 'is_online' ? <p>Complete now online?</p> : null }
                                                                            { setting === 'question_number' &&
                                                                                <div className='ml-auto mr-[10px]'>
                                                                                    <select value={questionNumber} onChange={(event)=>setNumberQuestions(event.target.value)} className='border-white border-[1px] rounded-[2px] text-center pl-[5px]' style={{ backgroundColor: '#c99f2e' }}>
                                                                                        <option>5</option>
                                                                                        <option>10</option>
                                                                                        <option>15</option>
                                                                                        <option>20</option>
                                                                                    </select>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                setting === 'title' &&
                                                                                <div className="flex items-center w-full">
                                                                                    <p className="text-left mr-[50px]">Worksheet Title</p>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="w-[100px] px-[5px] py-[2px] text-black border border-gray-300 rounded-[5px] shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-400"
                                                                                        placeholder="Title"
                                                                                        onChange={(e)=>setWorksheetTitle(e.target.value)}
                                                                                        value={worksheetSettings.title}
                                                                                        maxLength={40}
                                                                                    />
                                                                                </div>   
                                                                            }
                                                                            {
                                                                                setting === 'is_online' && (!user || user.teacher === true) &&
                                                                                <div className='ml-auto mr-[10px]'>
                                                                                    <input disabled={userSelectedTopics.indexOf('ordering_fractions') !== -1 || userSelectedTopics.indexOf('error_intervals') !== -1 || userSelectedTopics.indexOf('solving_quadratics') !== -1} type='checkbox' onChange={(e)=>setWorksheetOnlineSettings(e.target.checked)} checked={worksheetSettings.is_online} className='topicSelectButton' style={{ backgroundColor: '#c99f2e' }}/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            } 
                                                        </div>                         
                                                )}
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <input type='submit' onClick={()=>clearTopics()} className='btn topicButton hover:cursor-pointer mt-[10px]' style={{backgroundColor: '#413C58'}} value='Clear topics selected' />

                                <input onClick={()=>generateWorksheet()} type='submit' className='btn topicButton hover:cursor-pointer mt-[25px]' style={{backgroundColor: '#9C9C9C'}} value='Create Worksheet' /> 
                                <div>
                                    {
                                        worksheetQuestions.length > 0 ?
                                        <input onClick={()=>setViewWorksheet(true)} type='submit' className='btn topicButton hover:cursor-pointer mt-[10px]' style={{backgroundColor: '#9C9C9C'}} value='Back to Worksheet' /> 
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div>
            </div>
            }
        </div>
    );
}
