import React from 'react';

import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const LatexOptions = {
    delimiters: [
        { left: '~~', right: '~~', display: true },  // Block LaTeX
        { left: '~', right: '~', display: false }, // Inline LaTeX
    ],
  };

export default class WorksheetQuestions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        // Define the title
        const title = this.props.worksheetTitle;

        // Splitting questions into groups of two
        const groupedQuestions = [];
        for (let i = 0; i < this.props.worksheetQuestions.length; i += 2) {
            groupedQuestions.push(this.props.worksheetQuestions.slice(i, i + 2));
        }

        return (
            <div className='w-[700px]'>
                {groupedQuestions.map((group, groupIndex) => (
                    <div key={groupIndex} className='page mb-[20px] mt-[30px]'>
                        {/* Render the title only on the first page */}
                        {groupIndex === 0 && (
                            <h1 className='text-2xl font-bold text-center mb-[50px]'>{title}</h1>
                        )}

                        {group.map((question, questionNumber) => (
                            <div key={groupIndex * 1000 + questionNumber * 1000} className='mb-[10px] mt-[20px]'>
                                <p className='mb-[10px] break-words'>Q{groupIndex * 2 + questionNumber + 1}. <Latex delimiters={LatexOptions.delimiters}>{question[0]}</Latex></p>
                                <div className='h-[300px] border-solid border-2 border-[black] rounded-[5px]'>
                                    <div className='flex items-end justify-end mt-[260px] mr-[10px]'>
                                        <p>...............................................................</p>
                                    </div>
                                </div>
                                
                                { questionNumber === 0 ?
                                    <hr className='solid mt-[50px] mb-[50px]' />
                                    :
                                    null
                                }
                            </div>
                        ))}

                        
                    </div>
                ))}
            </div>
        );
    }
}
