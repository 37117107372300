import React, { useEffect, useState } from 'react';
import { Document, Page, Image, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  question: {
    flexDirection: 'column',
    width: '100%',
    marginTop: 10,
  },
  imageContainer: {
    marginLeft: 0, // Ensure no left margin
    marginRight: 5,
    maxWidth: 100, // Ensures the container doesn't exceed this dimension
    display: 'flex', // Flex display for alignment
    justifyContent: 'flex-start', // Align to the start (left) of the container
  },
  image: {
    height: 16, // Fixed height
    width: 'auto', // Adjust width automatically based on aspect ratio
    objectFit: 'contain', // Maintains aspect ratio of the image
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
  },
  questionText: {
    fontSize: 12,
    textAlign: 'left',
    marginRight: 5,
    flexShrink: 1,
  },
});

const WorksheetAnswersPDF = ({ images, worksheet, title }) => {
  const [currentWorksheet, setCurrentWorksheet] = useState(worksheet);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{title} Answers</Text>
        
        <View>
            {
                worksheet.map((question, key) => (
                    <View key={key * 1000} style={{ marginTop: 10 }}>
                        <Text style={styles.questionText}>Question {key + 1} Answer: </Text>
                        <View style={styles.imageContainer}>
                          <Image src={images[key]} style={styles.image} />
                        </View>
                        <Text style={[styles.questionText, { marginTop: 5 }]}>Question {key + 1} Steps: {question[2]}</Text>
                    </View>
                ))
            }
        </View>
      </Page>
    </Document>
  );
};

export default WorksheetAnswersPDF;
