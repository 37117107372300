import React, { useState } from "react";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-[#F4F5F7] w-full border-b-[1px] border-b-[#F4F5F7]">
      {/* Center container */}
      <div className="flex justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 w-full max-w-6xl">
          {/* Left side: Logo and navigation links */}
          <div className="flex items-center justify-center w-full">
            <div className="text-black text-2xl font-bold text-center">Maths Den</div>
            <div className="ml-10 hidden md:flex items-baseline space-x-4">
              <a
                href="/"
                className="text-black hover:text-gray-600 px-3 py-2 rounded-md text-md font-medium"
              >
                Home
              </a>
              <ul className="menu menu-horizontal px-1">
                <li>
                  <details>
                    <summary className="text-black px-2 py-2 rounded-md text-md font-medium">
                      Worksheet Generators
                    </summary>
                    <ul className="w-[250px] bg-[#F4F5F7]">
                      <li>
                        <a
                          className="text-black bg-[#F4F5F7] px-3 py-2 rounded-md text-md font-medium mb-[2px]"
                          href="/GCSE"
                        >
                          GCSE Question Generator
                        </a>
                      </li>
                    </ul>
                  </details>
                </li>
              </ul>
              <a
                href="/about"
                className="text-black hover:text-gray-600 px-3 py-2 rounded-md text-md font-medium"
              >
                About
              </a>
              <a
                href="/contact"
                className="text-black hover:text-gray-600 px-3 py-2 rounded-md text-md font-medium"
              >
                Contact
              </a>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-600 focus:outline-none border border-[1px] border-black"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
            href="/"
            className="text-black hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium ml-[5px]"
          >
            Home
          </a>
          <ul className="menu menu-horizontal px-1">
            <li>
              <details>
                <summary className="text-black px-3 py-2 rounded-md text-base font-medium cursor-pointer w-[85.5vw]">
                  Worksheet Generators
                </summary>
                <ul className="w-full mt-2 bg-[#F4F5F7]">
                  <li>
                    <a
                      className="text-black bg-[#F4F5F7] px-3 py-2 rounded-md text-base font-medium mb-[2px]"
                      href="/GCSE"
                    >
                      GCSE Worksheet Generator
                    </a>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <a
            href="/about"
            className="text-black hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium ml-[5px]"
          >
            About
          </a>
          <a
            href="/contact"
            className="text-black hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium ml-[5px]"
          >
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;