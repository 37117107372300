import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import { About } from './pages/About';
import { Contact } from "./pages/Contact";
import GCSE from "./pages/GCSE";
import { NoPage } from "./pages/NoPage";

import { Dashboard } from "./pages/authenticated/Dashboard";
import CreateWorksheet from "./pages/authenticated/CreateWorksheet";
import UserWorksheets from "./pages/authenticated/UserWorksheets";
import AssignWorksheet from "./pages/authenticated/AssignWorksheet";

import { useAuthStore } from './store/authStore';
import Login from "./pages/Login";
import Register from "./pages/Register";

import { useEffect } from "react";

function App() {
  /*const setCsrfToken = useAuthStore(state => state.setCsrfToken);

  useEffect(()=> {
    void setCsrfToken();
  }, [setCsrfToken]);*/

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/gcse' element={<GCSE />} />

      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/create_worksheet' element={<CreateWorksheet />} />
      <Route path='/your_worksheets' element={<UserWorksheets />} />
      <Route path='/assign_worksheet' element={<AssignWorksheet />} />

      {/*<Route path='/login' element={<Login />} />*/}
      {/*<Route path='/register' element={<Register />} />*/}

      <Route path='*' element={<NoPage />} />
    </Routes>
  );
}

export default App;
