import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import contactImage from '../assets/images/contact.png';

import CookiesBanner from "../components/CookiesBanner";

export const Contact = () => {
    useEffect(() => {
        document.title = "Contact | Maths Den";
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />

            <CookiesBanner />
            
            <div className="mt-5 flex-grow">
                <h1 className="text-3xl text-center font-bold">
                    Contact
                </h1>
                
                {/* Responsive container for text and image */}
                <div className="max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto mt-5 px-4">
                    <div className="text-center">
                        <p>
                            If you have any questions about our Worksheet Generators, need support, find a bug,
                            or want to share general feedback, please don't hesitate to send us a message! To contact us, you can use the following methods:
                        </p>
                        
                        <ul className="mt-3">
                            <li>
                                Email:{" "}
                                <a
                                    className="text-[#38A1FF] underline"
                                    href="mailto:contact@mathsden.com"
                                >
                                    contact@mathsden.com
                                </a>
                            </li>
                        </ul>
                        
                        <img src={contactImage} alt="Faq 2 from Brooklyn Illustrations Vector Set by Streamline" className="w-[200px] mx-auto mt-8" />
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    );
};
