import React, { useEffect, useState, useRef } from 'react';
import CanvasArea from './CanvasArea';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

import { symbolicEqual } from 'mathjs';

const LatexOptions = {
    delimiters: [
        { left: '~~', right: '~~', display: true },  // Block LaTeX
        { left: '~', right: '~', display: false }, // Inline LaTeX
    ],
  };

export default function WorksheetPreview({ worksheet }) {
    const [questionNumber, setQuestionNumber] = useState(0);
    const [question, setQuestion] = useState(worksheet[0]);
    const [correct, setCorrect] = useState(false);
    const [questionCompleted, setQuestionCompleted] = useState(false);
    const [correctNumber, setCorrectNumber] = useState(0);
    const [questionsCorrect, setQuestionsCorrect] = useState(Array(worksheet.length));
    const [resetCanvas, setResetCanvas] = useState(false);
    const [error, setError] = useState('');

    const topQuestionRef = useRef(null);

    const checkAnswer = () => {
        let userAnswer = document.getElementById('user_answer').value;
        userAnswer = userAnswer.replace(/\s+/g, ' ').trim();
        userAnswer = userAnswer.replaceAll(' ','');

        console.log(userAnswer);
        console.log(worksheet[questionNumber][1][0]);

        if (questionCompleted === false && userAnswer.length > 0) {
            setQuestionCompleted(true);
            scrollToElement();
            let currentCorrect = questionsCorrect;
            try {
                if ((userAnswer.toLowerCase() === worksheet[questionNumber][1][0].toString().toLowerCase()) || (symbolicEqual(userAnswer, worksheet[questionNumber][1][0].toString()))) {
                    setCorrect(true);
                    setCorrectNumber(correctNumber + 1);
                    currentCorrect[questionNumber] = true;
                } else {
                    setCorrect(false);
                    currentCorrect[questionNumber] = false;
                }
            } catch {
                setCorrect(false);
                currentCorrect[questionNumber] = false;
            }
        } else {
            setError('Please type an answer!');
            document.getElementById('error_modal').showModal();
        }
    }

    const scrollToElement = () => {
        const rect = topQuestionRef.current.getBoundingClientRect();
        if (rect.top < 0) {
            topQuestionRef.current.scrollIntoView();
        }
    };

    const nextQuestion = () => {
        if (questionNumber < worksheet.length) {
            scrollToElement();
            setQuestionNumber(questionNumber + 1);
            setQuestion(worksheet[questionNumber + 1]);
            setQuestionCompleted(false);
            setCorrect(false);
            document.getElementById('user_answer').value = '';
            setResetCanvas(true);
        }
    }

    const retryQuestions = () => {
        if (questionNumber >= worksheet.length) {
            setQuestionNumber(0);
            setQuestion(worksheet[0]);
            setQuestionCompleted(false);
            setCorrectNumber(0);
            setCorrect(false);
            //document.getElementById('user_answer').value = '';
            setResetCanvas(true);
        }
    }

    useEffect(()=> {
        setResetCanvas(false);
    }, [questionNumber]);

    return(
        <div>
            <dialog id="error_modal" className="modal">
                <div className="modal-box bg-error text-white">
                    <h3 className="font-bold text-lg">Error</h3>
                    <p className="py-4">{error}</p>
                    <div className="modal-action">
                    <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn btn-ghost rounded-[10px] text-white">Close</button>
                    </form>
                    </div>
                </div>
            </dialog>

            <div ref={topQuestionRef} style={{scrollMarginTop: '20px'}}></div>

            { questionNumber < worksheet.length ?
                <div className='mt-[20px] w-[90%] max-w-[700px] mx-auto'>
                    <div>
                            {correct && questionCompleted && 
                                <div role="alert" className="alert alert-success">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 shrink-0 stroke-current"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className='text-white'>Correct 🙂</span>
                                </div>
                            }
                            {!correct && questionCompleted && 
                                <div role="alert" className="alert alert-error">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 shrink-0 stroke-current"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className='text-white'>Incorrect ☹️ The answer was {question[1][0]}.</span>
                                </div>
                            }
                        
                        <div className='w-full max-w-[700px] mx-auto'>
                            <div className='mb-[10px] mt-[20px]'>
                                <p className='mb-[10px]'>Q{questionNumber+1}. <Latex delimiters={LatexOptions.delimiters}>{worksheet[questionNumber][0]}</Latex></p>
                                <div className='h-[525px] border-solid border-2 border-[black] rounded-[5px]'>
                                    <div className='mb-[20px] ml-[5px]'>
                                        <CanvasArea resetCanvas={resetCanvas} />
                                    </div>
                                    <div className='flex items-end justify-end mr-[10px]'>
                                        <input type='text' className="input input-bordered w-full max-w-xs mb-[10px] ml-[10px] rounded-[10px]" id='user_answer' placeholder='Answer' />
                                    </div>
                                </div>
                                <div className='flex'>
                                    {   !questionCompleted &&
                                        <a onClick={checkAnswer} className='btn btn-error text-white rounded-[10px] mt-[10px] ml-auto'>Submit</a>
                                    }
                                    {
                                        questionCompleted &&
                                        <a onClick={nextQuestion} className='btn btn-neutral text-white rounded-[10px] mt-[10px] ml-auto'>Next Question</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div className='mt-[20px] pb-[100px] text-center'>
                    <h2 className='text-xl'>You have completed this worksheet.</h2>
                    <p>Your score: <b>{correctNumber}/{worksheet.length}</b></p>
                    <button className='btn mt-[10px] ml-auto mr-auto rounded-[5px] text-white w-[175px] flex items-center justify-center' style={{backgroundColor: '#5F5566'}} onClick={() => retryQuestions()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                        </svg>
                        
                        Retry Questions
                    </button>
                    <div className='mt-[10px] text-center'>
                        <p>Summary of Question Answers:</p>
                        <ul className='list-disc list-inside pl-0 inline-block'>
                            {
                            questionsCorrect.map((question, key) => (
                                <li key={key * 1000} className='m-1'>{question ? `Question ${key + 1}: Correct` : `Question ${key + 1}: Incorrect`}</li>
                            ))
                            }
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
}