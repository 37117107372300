import React, { useEffect, useRef, useState } from "react";

export default function CanvasArea({ resetCanvas }) {
  const canvasReference = useRef(null);
  const contextReference = useRef(null);
  const [isPressed, setIsPressed] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  
  useEffect(() => {
    const canvas = canvasReference.current;

    const updateCanvasSize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 795) {
        canvas.width = 0.85*windowWidth; // Set to almost full width for smaller screens
        canvas.height = 400;
      } else {
        canvas.width = 680; // Set to the normal size for larger screens
        canvas.height = 400;
      }
    };

    updateCanvasSize(); // Set initial size
    window.addEventListener('resize', updateCanvasSize); // Listen for screen size changes

    const context = canvas.getContext("2d");
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.lineWidth = 4;
    contextReference.current = context;

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  useEffect(() => {
    if (resetCanvas) {
      clearCanvas();
    }
  }, [resetCanvas]);

  const clearCanvas = () => {
    const canvas = canvasReference.current;
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
  };

  const toggleDrawMode = () => setIsDrawingMode((prev) => !prev);

  const getCoordinates = (e) => {
    const canvas = canvasReference.current;
    let x, y;

    if (e.touches) {
      const rect = canvas.getBoundingClientRect();
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    } else {
      x = e.nativeEvent.offsetX;
      y = e.nativeEvent.offsetY;
    }

    return { x, y };
  };

  const beginDraw = (e) => {
    if (!isDrawingMode) return;
    e.preventDefault();
    const { x, y } = getCoordinates(e);
    contextReference.current.beginPath();
    contextReference.current.moveTo(x, y);
    setIsPressed(true);
  };

  const endDraw = (e) => {
    if (!isDrawingMode) return;
    e.preventDefault();
    contextReference.current.closePath();
    setIsPressed(false);
  };

  const updateDraw = (e) => {
    if (!isPressed || !isDrawingMode) return;
    e.preventDefault();
    const { x, y } = getCoordinates(e);
    contextReference.current.lineTo(x, y);
    contextReference.current.stroke();
  };

  return (
    <div>
      <button
        onClick={clearCanvas}
        className="border border-[black] pl-[10px] pr-[10px] pt-[2px] pb-[2px] rounded-[10px] mb-[5px] mt-[5px]"
      >
        Clear
      </button>
      <button
        onClick={toggleDrawMode}
        className="border border-[black] pl-[10px] pr-[10px] pt-[2px] pb-[2px] rounded-[10px] mb-[5px] mt-[5px] ml-[5px]"
      >
        {isDrawingMode ? "Stop Drawing" : "Draw"}
      </button>
      <canvas
        ref={canvasReference}
        tabIndex={0}
        onMouseDown={beginDraw}
        onMouseUp={endDraw}
        onMouseMove={updateDraw}
        onTouchStart={beginDraw}
        onTouchEnd={endDraw}
        onTouchMove={updateDraw}
        className="border border-[black] rounded-[5px]"
      />
    </div>
  );
}