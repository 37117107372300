import React, { useEffect, useState } from 'react';
import { Document, Page, Image, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 10,
    alignItems: 'center',
  },
  question: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  container: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap', // Allow wrapping of content
    justifyContent: 'flex-start', // Align the content to start
  },
  inlineContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    marginLeft: 10,
    marginRight: 5,
    height: 16,
    width: 'auto',
    maxWidth: '200px',
  },
  imageCenter: {
    height: '20px',
    width: 'auto',
  },
  centeredImageContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    display: 'flex', // This makes sure the image is treated as a flex item for centering
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    textAlign: 'left',
    marginRight: 5,
    flexShrink: 1,
  },
  box: {
    width: '90%',
    height: 250,
    borderWidth: 2,
    borderColor: 'black',
    borderStyle: 'solid',
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'center',
    position: 'relative',
  },
  divider: {
    borderTop: '1px solid #000',
    width: '90%',
    height: '3px',
    marginTop: 8,
    marginBottom: 8,
  },
  bottomText: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontSize: 10,
    color: '#000',
  },
});

const WorksheetQuestionsPDF = ({ images, worksheet, title }) => {
  const [currentWorksheet, setCurrentWorksheet] = useState(worksheet);

  useEffect(() => {
    if (worksheet.length > 0) {
      const updatedWorksheet = currentWorksheet.map(row => {
        return row.map(str => {
          if (typeof str === 'string') {
            let result = str.match(/~~[^~]*~~|~[^~]*~|[^~]+/g) || [];
            return result.map(item => item.trim().replace(/,$/, ''));
          }
          return str;
        });
      });
      setCurrentWorksheet(updatedWorksheet);
    }
  }, [worksheet]);

  let imageCount = 0;
  let questionIndex = 1;

  if (currentWorksheet[0].constructor === Array) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>{title}</Text>
          {currentWorksheet.map((question, key) => (
            <View key={key * 1000} wrap={false} style={styles.question}>
              <View style={styles.container}>
                <Text style={styles.text}></Text>
                <Text style={styles.text}>
                  {Array.isArray(question[0]) ? `Q${questionIndex++})   `+question[0][0].replace(/^~~/, '') : `Q${questionIndex++})   `+question[0].replace(/^~~/, '')}
                </Text>

                {Array.isArray(question[0]) &&
                  question[0].slice(1).map((subquestion, subquestionKey) => {
                    const isImage = subquestion.startsWith('~') && subquestion.endsWith('~');
                    const needsLineBreak = subquestion.startsWith('~~') && subquestion.endsWith('~~');

                    if (needsLineBreak) {
                      return (
                        <View key={subquestionKey * 1000} style={styles.centeredImageContainer} wrap={true}>
                          <Text>{'\n'}</Text>
                          <Image style={styles.imageCenter} src={images[imageCount++]} />
                        </View>
                      );
                    } else {
                      return (
                        <View style={styles.inlineContent} key={subquestionKey * 1000}>
                          {isImage ? (
                            <Image style={styles.image} src={images[imageCount++]} />
                          ) : (
                            <Text style={styles.text}>{subquestion.replace(/^~~/, '')}</Text>
                          )}
                        </View>
                      );
                    }
                  })}
              </View>

              <View style={styles.box} break={true}>
                <Text style={styles.bottomText}>......................................</Text>
              </View>

              {key !== currentWorksheet.length - 1 && <View style={styles.divider} />}
            </View>
          ))}
        </Page>
      </Document>
    );
  } else {
    return null;
  }
};

export default WorksheetQuestionsPDF;
