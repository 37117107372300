import React from "react";

import AuthenticatedNavbar from '../../components/AuthenticatedNavbar';



export default class SaveWorksheet extends React.Component {
    constructor(props) {
        super(props);
    }

    

    render() {
        return(
            <div>
                <AuthenticatedNavbar />
                <div className='mt-[20px] pb-[100px]'>
                    <div id='questions'>
                        
                    </div>
                </div>
            </div>
        );
    }
}