import React, { useState } from "react";

import { Link, useNavigate } from 'react-router-dom';

function Navbar({ logout, user }) {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleLogout = async() => {
      try {
          await logout();
          navigate('/');
      } catch(error) {
          console.error(error);
      }
  }

  return (
    <nav className="bg-[#2C3E50]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Left side: Logo and navigation links */}
          <div className="flex items-center">
            <div className="text-white text-2xl font-bold">Maths Den</div>
            <div className="ml-10 hidden md:flex items-baseline space-x-4">
                <a
                    href="/dashboard"
                    className="text-white hover:bg-[#3D556D] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                Dashboard
                </a>
                <a
                    href="/create_worksheet"
                    className="text-white hover:bg-[#3D556D] hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                Create
                </a>
                { user.teacher === true &&
                <ul className="menu menu-horizontal px-1">
                    <li>
                        <details>
                        <summary className="text-white hover:bg-[#3D556D] hover:text-white px-3 py-2 rounded-md text-sm font-medium">Assignments</summary>
                        <ul className="p-2 w-[200px]">
                            <li><a className="rounded-[10px]" href='/your_worksheets'>Your worksheets</a></li>
                            <li><a className="rounded-[10px]">Tasks</a></li>
                        </ul>
                        </details>
                    </li>
                </ul>
                }
            </div>
          </div>
          {/* Right side: Logout button and SVG icon */}
          <div className="hidden md:flex items-center space-x-2">
            <a onClick={handleLogout}>
              <button className="text-white hover:bg-[#3D556D] hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                Logout
              </button>
            </a>
            <a href="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="white"
                className="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fillRule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            </a>
          </div>
          {/* Mobile menu button */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#3D556D] focus:outline-none focus:bg-[#3D556D] focus:text-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
            href="/dashboard"
            className="text-white hover:bg-[#3D556D] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Dashboard
          </a>
          <a
            href="/create_worksheet"
            className="text-white hover:bg-[#3D556D] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Create
          </a>
          { user.teacher === true &&
            <li>
                <details>
                <summary className="text-white hover:bg-[#3D556D] hover:text-white hover:cursor-pointer select-none block px-3 py-2 rounded-md text-base font-medium">Assignments</summary>
                <ul className="p-2">
                    <li><a href='/your_worksheets' className="text-white hover:bg-[#3D556D] hover:text-white hover:cursor-pointer select-none block px-3 py-2 rounded-md text-base font-medium">Your worksheets</a></li>
                    <li><a className="text-white hover:bg-[#3D556D] hover:text-white hover:cursor-pointer select-none block px-3 py-2 rounded-md text-base font-medium">Tasks</a></li>
                </ul>
                </details>
            </li>
          }
          {/* Mobile Login button */}
          <a
            onClick={handleLogout}
            className="text-white hover:bg-[#3D556D] hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Logout
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
