import React, { useState, useEffect } from 'react';
import LaTeXToImage from './LatexToImage';
import WorksheetQuestionsPDF from './WorksheetQuestionsPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import 'katex/dist/katex.min.css';

const WorksheetQuestionsButton = ({ worksheet, title }) => {
  const [latexExpressions, setLatexExpressions] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(true);
  const [generatedLatexIndexes, setGeneratedLatexIndexes] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true);

  useEffect(() => {
    const extractLatexExpressions = (worksheetArray) => {
      const expressions = [];
      worksheetArray.forEach(row => {
        row.forEach(text => {
          if (typeof text === 'string') {
            const normalizedText = text.replace(/~~/g, '~');
            const matches = normalizedText.match(/~(.*?)~/g);
            if (matches) matches.forEach(match => expressions.push(match.slice(1, -1)));
          }
        });
      });
      return expressions;
    };

    const latexArray = extractLatexExpressions(worksheet);
    setLatexExpressions(latexArray);

    const normalizedWorksheet = worksheet.map(row => row.map(text => 
      typeof text === 'string' ? text.replace(/~~/g, '~') : text
    ));

    setCurrentQuestions(normalizedWorksheet);
    setGeneratedImages(new Array(latexArray.length).fill(null));
  }, [worksheet]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    if (latexExpressions.length > 0 && generatedLatexIndexes.length < latexExpressions.length) {
      const generateImages = debounce(() => {
        latexExpressions.forEach((latex, index) => {
          if (!generatedLatexIndexes.includes(index)) {
            const latexButton = document.getElementById(`latex-container-${index}`);
            if (latexButton) latexButton.click();
          }
        });
      }, 200);

      generateImages();
    } else {
      setIsGenerating(false);
    }
  }, [latexExpressions, generatedLatexIndexes]);

  const handleImageGenerationComplete = (index, imageUrl) => {
    setGeneratedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index] = imageUrl;
      return updatedImages;
    });

    setGeneratedLatexIndexes((prevIndexes) => [...prevIndexes, index]);

    if (generatedLatexIndexes.length + 1 === latexExpressions.length) {
      setIsGenerating(false);
    }
  };

  const handleLoadingStatus = (loading) => {
    if (loading) {
      setLoadingStatus(true);
    } else {
      // Add a delay before setting loadingStatus to false
      setTimeout(() => setLoadingStatus(false), 3000); // Adjust delay as needed
    }
  };

  return (
    <div>
      <div style={{ display: 'none' }}>
        {latexExpressions.map((latex, index) => (
          <LaTeXToImage
            key={index}
            latex={latex}
            index={index}
            onImageGenerated={(imageUrl) => handleImageGenerationComplete(index, imageUrl)}
          />
        ))}
      </div>

      {isGenerating ? (
        <p className='text-center mt-[10px]'>Generating PDF... Please wait...</p>
      ) : (
        <PDFDownloadLink
          document={<WorksheetQuestionsPDF images={generatedImages} worksheet={worksheet} title={title} />}
          fileName={title.replace(/ /g, "_") + ".pdf"}
          className="btn topicButton hover:cursor-pointer flex justify-center mb-[10px] mt-[10px]"
          style={{
            backgroundColor: loadingStatus ? '#9C9C9C' : '#DD7373',
            width: '200px',
            cursor: loadingStatus ? 'wait' : 'pointer'
          }}
        >
          {({ loading }) => {
            handleLoadingStatus(loading);
            return loadingStatus ? 'Loading document...' : 'Download to PDF';
          }}
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default WorksheetQuestionsButton;
