import React from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';

const LatexOptions = {
    delimiters: [
        { left: '[[', right: ']]', display: true },  // Block LaTeX
        { left: '[', right: ']', display: false }, // Inline LaTeX
    ],
  };

export default class WorksheetAnswers extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        // Split questions into groups of five
        const groupedQuestions = [];
        for (let i = 0; i < this.props.worksheetQuestions.length; i += 5) {
            groupedQuestions.push(this.props.worksheetQuestions.slice(i, i + 5));
        }

        // Define the title
        const title = this.props.worksheetTitle + " Answers";

        return (
            <div className='w-[700px] break-words'>
                {groupedQuestions.map((group, groupIndex) => (
                    <div key={groupIndex} className='page mb-[20px] mt-[30px]'>
                        {groupIndex === 0 && (
                            <h1 className='text-2xl font-bold text-center mb-[50px]'>{title}</h1>
                        )}

                        {/* Render each group of five questions */}
                        {group.map((question, questionNumber) => (
                            <div key={groupIndex * 1000 + questionNumber * 1000} className='mb-[20px] mt-[10px]'>
                                <p>
                                    Q{groupIndex * 5 + questionNumber + 1}. <Latex delimiters={LatexOptions.delimiters}>{question[0]}</Latex>
                                    <br />
                                    <strong>Answer:</strong> <Latex>{question[1][1]}</Latex>
                                    <br />
                                    <strong>Steps:</strong> {question[2]}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}
