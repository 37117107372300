import React, { useEffect, useRef } from 'react';
import html2canvas from 'html2canvas-pro';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const LaTeXToImage = ({ latex, index, onImageGenerated }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && latex) {
      katex.render(latex, containerRef.current, { throwOnError: false });
    }
  }, [latex]);

  const handleImageGeneration = () => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.left = '-9999px'; 
    document.body.appendChild(container);

    katex.render(latex, container, { throwOnError: false });

    html2canvas(container, {
      scale: 3,
      useCORS: true,
      backgroundColor: null,
    }).then((canvas) => {
      const imageDataUrl = canvas.toDataURL('image/png');
      onImageGenerated(imageDataUrl); // Pass back the generated image URL

      document.body.removeChild(container); // Cleanup
    });
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <button
        style={{ display: 'none' }}
        id={`latex-container-${index}`}
        onClick={handleImageGeneration}
      >
        Generate Image
      </button>
    </div>
  );
};

export default LaTeXToImage;