import React from 'react';

export const NoPage = () => {
    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <h1 className="text-center">
                <b>Uh oh... we ran into a problem...</b><br/>
                Error 404: no page found
            </h1>
            <a href='/' className="btn btn-link text-[#5680FF]">Go to home</a>
        </div>
    );
}
