import React, { useEffect } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import WorksheetPreview from '../components/Worksheet Generation/Online/WorksheetPreview';

import BannerHome from '../assets/images/home.png';
import WorkingHome from '../assets/images/working_home.png';
import BuildingHome from '../assets/images/building_home.png';

import BackgroundImage from '../assets/images/cover_blurred.jpg';

import CookiesBanner from '../components/CookiesBanner';

export default function Home() {

    useEffect(()=> {
        document.title = 'Home | Maths Den';
    }, []);

    return(
        <div className="flex flex-col min-h-screen">
            <Navbar />

            <CookiesBanner />

            <div className='flex-grow'>
                {/* Main banner section with background image */}
                <div 
                    className='flex items-center justify-center mt-[50px]'
                    style={{ 
                        backgroundImage: `url(${BackgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '400px'
                    }}
                    >
                    <div className='w-full p-4 text-center'>
                        <h1 className='font-bold text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl mx-auto [text-shadow:_0_1px_0_rgb(0_0_0_/_60%)]'>
                            Finding worksheets made <br/><span className='text-[#007FFF]'>easy</span>
                        </h1>
                    </div>
                </div>

                <div className='mt-[20px] p-4 sm:p-6 md:p-8 lg:p-10'>
                    <div className='text-center text-sm sm:text-base md:text-lg lg:text-lg max-w-[700px] mx-auto leading-relaxed'>
                        Welcome to Maths Den.
                        <br/>
                        At Maths Den, we make it easy to access high-quality worksheets tailored for students and teachers. 
                        Through our Worksheet Generators, you can save time while finding targeted questions to help improve your learning experience.
                        <img 
                            src={BannerHome}
                            alt='Overworked Employee 2 from Brooklyn Illustrations Vector Set by Streamline'
                            className='w-[60%] sm:w-[60%] md:w-[60%] lg:w-[50%] max-w-[250px] ml-auto mr-auto mt-[10px] mb-[100px]'
                        />

                        <div>
                            <b><i>What makes Maths Den unique?</i></b>
                            <ul className='list-disc space-y-4'>
                                <li>
                                    <b>Worksheet Generators with Customisation</b>: effortlessly create worksheets based on your chosen topics.
                                </li>
                                <li>
                                    <b>Foundation and Higher-Level Content</b>: we cover the full spectrum of GCSE topics, including both Foundation and Higher-tier material (which is coming soon as a paid tier).
                                </li>
                                <li>
                                    <b>Variety</b>: our questions feature different numbers every time, meaning that you can repeat failed attempts again and again.
                                </li>
                            </ul>
                        </div>

                        <div className='mt-[50px]'>
                            <b><i>Why should you choose Maths Den?</i></b>
                            <ul className='list-disc space-y-4'>
                                <li>
                                    <b>Quick and Easy Worksheet Generation</b>: Say goodbye to the hassle of searching for questions - get high-quality resources with just one click.
                                </li>
                                <li>
                                    <b>Personalised Worksheets</b>: Select topics and set question levels that align with your learning goals.
                                </li>
                                <li>
                                    <b>User-Friendly Design</b>: our platform is designed to make it as easy as possible to navigate and use to ensure that you can focus on the Maths.
                                </li>
                            </ul>
                        </div>

                        <div className='mt-[50px]'>
                            We currently provide one Worksheet Generator for the GCSE exams.
                            If you want to access this Worksheet Generator you can click on this <a href='/gcse' className='link text-[#38A1FF]'>link</a> or using the navbar.
                        </div>

                        <div className='mt-[50px]'>
                            If you have any enquires about Maths Den please go to the <a href='/contact' className='link text-[#38A1FF]'>Contact</a> page.
                        </div>
                    </div>
                </div>
                
            </div>

            <Footer />
        </div>
    );
}