import React from "react";
import { useEffect } from "react";

import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/authStore';

import AuthenticatedNavbar from '../../components/AuthenticatedNavbar';

export const Dashboard = () => {
    const { isAuthenticated, user, logout, fetchUser } = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
        void fetchUser().then(()=>{
            if (!isAuthenticated) {
                navigate('/');
            }
        });
    }, [fetchUser]);

    // Only render when user and isAuthenticated are valid
    if (!isAuthenticated || !user) {
        return null;
    }

    return(
        <div>
            <AuthenticatedNavbar logout={logout} user={user} />
            <div className='mt-[20px]'>
                <h1 className='text-3xl text-center'><b>Dashboard</b></h1>
                { isAuthenticated ?
                    <div>
                        <p className='text-center'>Howdy {user?.username.split('@')[0]}!</p>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
}